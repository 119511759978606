<template>
  <div class="create-input">
    <div
      class="input-box code "
      :class="{
        'error-class': isError,
        success: !isError && myValue != '' && successContent != '',
        icon: icon != ''
      }"
    >
      <div
        v-if="icon != ''"
        class="icon-body"
        :style="'background-image: url(' + icon + ');'"
      ></div>
      <div
        class="code-body"
        :class="{ active: time != 60 }"
        @click.stop="clickCode"
      >
        {{ time == 60 ? "发送验证码" : time + "秒" }}
      </div>
      <input
        type="text"
        :placeholder="placeholder"
        v-model="myValue"
        @input="FormValidation()"
        @blur="FormValidation()"
      />
      <div class="describe" v-if="successContent != ''">
        {{ successContent }}
      </div>
    </div>
    <small class="error-text" v-show="isError">{{ error_text }}</small>
  </div>
</template>
<script>
export default {
  props: {
    value: {
      //vue语法糖
      type: Object,
      default: () => {
        return { value: "", isError: false };
      }
    },
    submitType: {
      type: Boolean,
      default: false
    },
    placeholder: {
      type: String,
      default: "请输入验证码"
    },
    error_content: {
      type: String,
      default: ""
    },
    voucherCode: {
      //发送凭证
      type: String,
      default: null
    },
    is_required: {
      type: Boolean,
      default: true
    },
    icon: {
      type: String,
      default: ""
    },
    is_required_text: {
      //必填项内容为空时提示
      type: String,
      default: "验证码不能为空"
    },
    //成功文本提示
    successContent: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      myValue: this.value.value,
      error_text: "",
      isError: this.value.isError,
      time: 60
    };
  },
  watch: {
    myValue(value) {
      this.$emit("input", { value, isError: this.isError });
    },
    isError(value) {
      this.$emit("input", { value: this.myValue, isError: value });
    },
    value: {
      handler(vel, old) {
        this.myValue = vel.value;
        if (vel.isError && vel.isError != this.isError) {
          this.FormValidation();
          this.error_text = this.error_content;
        }
        this.isError = vel.isError;
      },
      deep: true
    },
    submitType: {
      handler(value) {
        if (value) {
          this.FormValidation();
        }
      },
      immediate: true
    }
  },
  methods: {
    FormValidation: function() {
      let res;
      let type = true;
      if (this.is_required) {
        if (this.myValue == null || this.myValue.length < 1) {
          type = false;
          this.error_text = this.is_required_text;
          this.isError = !type;
          return;
        }
        let text = this.error_content == "" ? false : this.error_content;
        this.error_text = text || "验证码失效";
      }
      if (this.voucherCode) {
        if (
          !/(^1[3-9][0-9]{9}$)|(^\w+((-w+)|(\.\w+))*\@[A-Za-z0-9]+((\.|-)[A-Za-z0-9]+)*\.[A-Za-z0-9]+$)/.test(
            this.voucherCode
          )
        ) {
          this.$emit("voucherErr");
          return;
        }
      } else if (this.placeholder.match(/邮箱/)) {
        if (
          !/^\w+((-w+)|(\.\w+))*\@[A-Za-z0-9]+((\.|-)[A-Za-z0-9]+)*\.[A-Za-z0-9]+$/.test(
            this.myValue
          )
        ) {
          this.isError = true;
          let text = this.error_content == "" ? false : this.error_content;
          this.error_text = text || "无效邮箱";
          return;
        }
        this.isError = false;
      } else if (this.placeholder.match(/手机/)) {
        if (!/^1[3-9][0-9]{9}$/.test(this.myValue)) {
          this.isError = true;
          let text = this.error_content == "" ? false : this.error_content;
          this.error_text = text || "无效号码";
          return;
        }
        this.isError = false;
      }
      this.isError = !type;
    },
    clickCode() {
      if (this.time != 60) return;
      if (this.voucherCode != null) {
        if (
          !/(^1[3-9][0-9]{9}$)|(^\w+((-w+)|(\.\w+))*\@[A-Za-z0-9]+((\.|-)[A-Za-z0-9]+)*\.[A-Za-z0-9]+$)/.test(
            this.voucherCode
          )
        ) {
          this.$emit("voucherErr");
          return;
        }
      } else if (this.placeholder.match(/邮箱/)) {
        if (
          !/^\w+((-w+)|(\.\w+))*\@[A-Za-z0-9]+((\.|-)[A-Za-z0-9]+)*\.[A-Za-z0-9]+$/.test(
            this.myValue
          )
        ) {
          this.isError = true;
          let text = this.error_content == "" ? false : this.error_content;
          this.error_text = text || "无效邮箱";
          return;
        }
        this.isError = false;
      } else if (this.placeholder.match(/手机/)) {
        if (!/^1[3-9][0-9]{9}$/.test(this.myValue)) {
          this.isError = true;
          let text = this.error_content == "" ? false : this.error_content;
          this.error_text = text || "无效号码";
          return;
        }
        this.isError = false;
      }
      this.$emit("clickCode");
      this.time--;
      window.int = setInterval(() => {
        if (this.time > 0) this.time--;
        if (this.time == 0) {
          this.time = 60;
          window.clearInterval(int);
        }
      }, 1000);
    }
  }
};
</script>
<style lang="scss" scoped>
@import "@/assets/css/common.scss";
input {
  background: none;
  outline: none;
  border: 0px;
}
.create-input {
  margin-bottom: 20px;
  .error-text {
    width: 100%;
    display: inline-block;
    margin-top: 4px;
    font-size: 14px;
    color: red;
    text-align: left;
  }
  .input-box {
    width: 100%;
    min-height: 54px;
    position: relative;
    &.error-class {
      border-color: red;
      input {
        border-color: red;
        &:focus {
          border-color: red;
        }
      }
    }
    &.success {
      input {
        border-bottom: none;
        border-radius: 8px 8px 0 0;
        &:focus {
          border-width: 1px;
          border-bottom: none;
          border-radius: 8px 8px 0 0;
        }
      }
      .describe {
        display: block;
      }
    }
    &.icon {
      input {
        padding-left: 47px;
      }
    }
    .icon-body {
      position: absolute;
      left: 17px;
      top: 0;
      width: 20px;
      height: 54px;
      background-size: 20px auto;
      background-position: center center;
      background-repeat: no-repeat;
    }
    .code-body {
      position: absolute;
      right: 5px;
      top: 4.5px;
      width: 150px;
      height: 45px;
      font-size: 18px;
      font-weight: 500;
      color: $fff;
      line-height: 45px;
      text-align: center;
      background-color: $green;
      border-radius: 8px;
      cursor: pointer;
      &.active {
        background: #9a9999;
      }
    }
    input {
      font-family: $font;
      box-sizing: border-box;
      border: 1px solid $grays;
      font-size: 18px;
      color: $fontSize-gray;
      width: 100%;
      min-height: 54px;
      line-height: 200%;
      // max-width: 420px;
      min-width: 100px;
      // line-height: 2.4rem;
      border-radius: 8px;
      padding-left: 10px;
      padding-right: 160px;
      &:focus {
        color: $black;
        border: 2px solid $gray;
      }
      &::placeholder {
        color: $fontSize-gray;
      }
    }
    .describe {
      display: none;
      width: 100%;
      min-height: 54px;
      color: $black;
      font-size: 14px;
      padding: 10px 15px;
      box-sizing: border-box;
      background-color: $orangeLight;
      line-height: 1.6;
      text-align: left;
      border: 1px solid $grays;
      border-radius: 0 0 8px 8px;
    }
  }
}
</style>
